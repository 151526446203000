import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          Caio Miranda
        </p>
        <p>
          Desenvolvedor Full Stack
          <br/>
          Projetos são mais importantes que meu site
        </p>
      </header>

      <div className="content">
        <div className="content-flex">
          <p className="content-title">Com o que trabalho</p>
          <p>
            ReactJS, JavaScript, HTML, CSS e PHP
          </p>
        </div>
        <div className="content-flex">
          <p className="content-title">Meus Projetos</p>
          <p className="content-paragraph" >
            <a className="content-link" href="https://gitlab.com/cailir" target="_blank" rel="noreferrer">Acesse meu GitLab</a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default App;
